import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import useRichText from "../hooks/useRichTexts";
import Layout from "../fragments/layout/Layout";
import TextBox from "../components/TextBox/TextBox";
import GoBack from "../components/GoBack/GoBack";
import Seo from "../components/seo";

export const query = graphql`
  query ($slug: String!) {
    contentfulProyecto(slugUrl: { eq: $slug }) {
      titleSeo
      descriptionSeo {
        descriptionSeo
      }
      ao
      descripcin {
        raw
      }
      lugarTtulo
      subttulo
      imagenesMobile {
        gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
      }
      imagenesDesktop {
        gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
      }
    }
  }
`;

const Detalle = (props) => {
  return (
    <Layout>
      <Seo
        title={props.data.contentfulProyecto.lugarTtulo}
        lang="es"
        titleSEO={props.data.contentfulProyecto.titleSeo}
        description={
          props.data.contentfulProyecto.descriptionSeo.descriptionSeo
        }
      />
      <div className="GoBack-project" style={{ textAlign: "right" }}>
        <GoBack />
      </div>
      <div className="detalle-container">
        <div className="detalle-text ">
          <h1 style={{ marginBottom: 0 }}>
            {props.data.contentfulProyecto.lugarTtulo}
          </h1>
          <p style={{ marginBottom: 20 }}>
            <small>
              {props.data.contentfulProyecto.subttulo || "Proyecto"},{" "}
              {props.data.contentfulProyecto.ao}
            </small>
          </p>

          <div className="project-text-responsive-xs">
            <TextBox>
              {useRichText(props.data.contentfulProyecto?.descripcin)}
            </TextBox>
          </div>

          <div className="project-text-responsive-lg">
            {useRichText(props.data.contentfulProyecto?.descripcin)}
          </div>
        </div>

        <div className="detalle-img-xs mt-4">
          {props.data.contentfulProyecto.imagenesMobile.map((img, i) => (
            <GatsbyImage
              key={i}
              image={getImage(img)}
              style={{ marginBottom: 30 }}
              alt="img"
            />
          ))}
        </div>
        <div className="detalle-img-xl mt-4">
          {props.data.contentfulProyecto.imagenesDesktop.map((img, i) => (
            <div key={i} className="detalle-img-xl-container">
              <GatsbyImage
                image={getImage(img)}
                style={{ marginBottom: 30 }}
                alt="img"
                className="detalle-img-xl-container-img"
              />
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Detalle;
