import  React,{ useState } from 'react'
import { BiChevronDown } from '@react-icons/all-files/bi/BiChevronDown'
import { BiChevronUp } from '@react-icons/all-files/bi/BiChevronUp'
import './TextBox.scss'

const TextBox = ({ children }) => {
  const [ wider, setWider ] = useState(true)

  const onClick = () => {
    setWider(!wider)
    if (window !== undefined) {
      window.scrollTo({top: 0, behavior: 'smooth'})
    }
  }

  return (
    <>
    <div className={`${wider ? "text-box small-box-text" : "text-box large-box-text"}`}>
      { children }
      <button onClick={onClick} className="read-more">
      {
        wider ? <BiChevronDown fontSize="1.2rem" /> : <BiChevronUp fontSize="1.2rem" />
      }
      </button>
    </div>
    </>
  )
}

export default TextBox
